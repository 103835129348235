<template>
	<div class="partial partial--csr csr">
		<transition
			name="fade"
			mode="in-out">
		<component :is="content" />
		</transition>
	</div>
</template>

<script>
const Stem = () => import(/* csr */ '@/views/partials/csr/Stem')
const Action = () => import(/* csr */ '@/views/partials/csr/Action')
const Sustainability = () => import(/* csr */ '@/views/partials/csr/Sustainability')
const Talent = () => import(/* csr */ '@/views/partials/csr/Talent')
const Allyship = () => import(/* csr */ '@/views/partials/csr/Allyship')
const ERG = () => import(/* csr */ '@/views/partials/csr/ERG')

export default {
	name: 'PartialCsr',
	data: () => ({
		article: null,
	}),
	components: {
	},
	computed: {
		csr() {
			return {
				stem: Stem,
				action: Action,
				sustainability: Sustainability,
				talent: Talent,
				allyship: Allyship,
				erg: ERG
			}
		},
		content() {
			if (!this.article) return
			return this.csr[this.article]
		},
	},
	mounted() {
		this.article = this.$route.params.article
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(val) {
				const { article } = val.params
				if (!article) return
				this.article = article
			},
		},
	}
}
</script>

<style scoped lang="scss">
.csr {
	background-color: white;

	::v-deep &__body {
		@include gutter('padding', 1);
		@include gutter('padding-top', 2);
		@include fontSize(18px);
		line-height: 1.5;
		margin-left: auto;
		margin-right: auto;
		width: 100%;

		@include media-query-min-width('tablet') {
			@include gutter('padding', 3);
		}

		@include media-query-min-width('desktop') {
			max-width: $content-container-lg;
			@include gutter('padding-top', 5);
		}

		p {
			@include gutter('margin-bottom', 1.5);
			margin-top: 0;
		}

		blockquote {
			@include gutter('margin-bottom', 2);
			@include fontSize(24px);
			color: color_('primary');
			font-style: italic;
			font-weight: bold;
			line-height: 1.4;
			margin: 0;
			padding: 0 0 0 24px;
			border-left: 8px solid color_('secondary');

			@include media-query-min-width('tablet') {
				@include gutter('margin-bottom', 4);
			}
		}

		h2 {
			@include fontSize(48px);
			@include gutter('margin-bottom', 1);
			color: color_('primary','dark');
			font-family: $font-family-secondary;
			font-weight: 900;
			line-height: 1;
			text-transform: uppercase;

			&.small {
				@include fontSize(32px);
				color: color_('primary','dark');
			}

			&.large {
				@include fontSize(64px);
				color: color_('primary','dark');
			}

			span {
				color: color_('primary');
			}

			@include media-query-max-width('phone') {
				br {
					display: none;
				}
			}
		}

		.body-columns {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: space-between;

			@include media-query-min-width('tablet') {
				flex-direction: row;
			}

			&__content {
				// @include gutter('padding-right', 3);

				@include media-query-min-width('tablet') {
					@include gutter('padding-right', 3);
				}

				@include media-query-min-width('desktop') {
					@include gutter('padding-right', 6);
				}
			}

			&__images {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 100%;

				@include media-query-min-width('tablet') {
					display: block;
				}

				img {
					@include gutter('margin-bottom', 2);
					max-width: 100%;

					@include media-query-min-width('tablet') {
						@include gutter('margin-bottom', 4);
						max-width: 266px;
					}

					@include media-query-min-width('desktop') {
						max-width: initial;
					}
				}
			}

			&--small {
				@include media-query-min-width('desktop') {
					max-width: 70%;
					margin: 0 auto;
				}
			}

			&--reverse {
				flex-direction: column-reverse;

				@include media-query-min-width('tablet') {
					flex-direction: row-reverse;
				}

				.body-columns__content {
					@include media-query-min-width('tablet') {
						@include gutter('padding-left', 3);
						@include gutter('padding-right', 0);
					}

					@include media-query-min-width('desktop') {
						@include gutter('padding-left', 6);
						@include gutter('padding-right', 0);
					}
				}
			}
		}
	}

	::v-deep .body-quote {
		@include ratio('hero');
		@include gutter('margin-top', 3);
		@include gutter('margin-bottom', 3);
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		max-height: 700px;
		min-height: 360px;
		position: relative;
		//overflow-x: hidden;

		@include media-query-min-width('tablet') {
			@include gutter('margin-top', 1);
			@include gutter('margin-bottom', 1);
		}

		&__content {
			@include gutter('padding-left', 1);
			@include gutter('padding-right', 1);
			color: color_('primary', 'text');
			display: flex;
			align-items: center;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			margin: 0 auto;

			@include media-query-min-width('tablet') {
				@include gutter('padding-left', 3);
				@include gutter('padding-right', 3);
			}

			@include media-query-min-width('desktop') {
				max-width: $content-container-lg;
			}

			&--left {

				.body-quote__quote {
					@include media-query-min-width('tablet') {
						max-width: 60%;
					}

					@include media-query-min-width('desktop') {
						max-width: 52%;
					}
				}

			}

			&--right {
				align-items: flex-end;
				.body-quote__quote {
					@include media-query-min-width('tablet') {
						max-width: 60%;
					}

					@include media-query-min-width('desktop') {
						max-width: 52%;
					}
				}

			}
		}

		&__quote {
			@include fontSize(18px);
			@include gutter('margin-bottom', 1);
			font-style: italic;
			line-height: 1.5;

			@include media-query-min-width('tablet') {
				@include fontSize(20px);
			}

			@include media-query-min-width('desktop') {
				@include fontSize(24px);
			}
		}

		&__credit {
			@include fontSize(16px);
			font-style: normal;
			margin: 20px 0;
		}

		&__before {
			content: "";
			background-image: url('~@/assets/csr/quoteLeft@2x.png');
			background-size: cover;
			background-position: center top;
			background-repeat: no-repeat;
			display: block;
			height: 79px;
			width: 100px;
			position: absolute;
			top: -50px;
			left: -40px;

			@include media-query-min-width('tablet') {
				height: 118px;
				width: 150px;
			}

			@include media-query-min-width('desktop') {
				height: 178px;
				width: 226px;
			}
		}

		&__after {
			content: "";
			background-image: url('~@/assets/csr/quoteRight@2x.png');
			background-size: cover;
			background-position: center top;
			background-repeat: no-repeat;
			display: block;
			height: 79px;
			width: 100px;
			position: absolute;
			bottom: -50px;
			right: -40px;
			left: auto;
			top: auto;

			@include media-query-min-width('tablet') {
				height: 118px;
				width: 150px;
			}

			@include media-query-min-width('desktop') {
				height: 178px;
				width: 226px;
			}
		}
	}

	::v-deep .image-list {
		@include gutter('margin-top', 3);
		@include gutter('margin-bottom', 3);

		&__item {
			@include gutter('margin-bottom', 3);
			display: flex;
			flex-direction: column;
			align-items: center;

			@include media-query-min-width('tablet') {
				flex-direction: row;
			}

			img {
				width: 100%;

				@include media-query-min-width('phone') {
					max-width: 380px;
				}

				@include media-query-min-width('desktop') {
					max-width: 548px;
				}
			}

			h2 {
				margin-top: 0;
			}

			figure {
				@include fontSize(18px);
				color: color_('text', 'light');
				font-family: $font-family-secondary;
				text-transform: uppercase;
				padding: 0;
				margin-left: 0;

				@include media-query-min-width('tablet') {
					@include fontSize(20px);
				}

				@include media-query-min-width('desktop') {
					@include fontSize(24px);
				}
			}

			div {

				@include media-query-min-width('tablet') {
					@include gutter('padding-right', 2);
				}

				@include media-query-min-width('desktop') {
					@include gutter('padding-right', 4);
				}
			}

			&--reverse {
				flex-direction: column;

				@include media-query-min-width('tablet') {
					flex-direction: row-reverse;
				}

				div {
					@include media-query-min-width('tablet') {
						@include gutter('padding-left', 2);
						@include gutter('padding-right', 0);
					}

					@include media-query-min-width('desktop') {
						@include gutter('padding-left', 4);
						@include gutter('padding-right', 0);
					}
				}
			}
		}
	}

	::v-deep .video-container {
		border-radius: 20px;
		overflow: hidden;
		display: flex;
		width: 100%;
		margin-bottom: 20px;

		iframe {
			width: 100%;
		}
	}

	::v-deep .body-box {
		@include gutter('padding', 1.5);
		background: #fff;
		box-shadow: 0 0 24px rgba(0, 0, 0, .16);
		border-radius: 20px;
		height: 100%;
		margin-bottom: 16px;

		p {
			@include gutter('margin-bottom', 1);
			@include fontSize(16px);
		}

		h2 {
			margin-top: 0;
		}

		h3 {
			//@include fontSize(18px);
			display: flex;
			align-items: center;
			font-weight: bold;
			line-height: 1.25;
			margin-top: 0;

			img {
				margin: 0 20px 0 0;
			}
		}
	}
}
</style>
